import React, { useEffect, useState } from 'react';
import WebApp from '@twa-dev/sdk';

const TelegramWebApp = () => {
    const [isBrowser, setIsBrowser] = useState(false);
    
    useEffect(() => {
        WebApp.ready();
        WebApp.MainButton.hide();
        WebApp.setHeaderColor('secondary_bg_color');
        
        const checkBrowser = () => {
            const isTelegramWebView = window.Telegram && window.Telegram.WebApp;
            setIsBrowser(!isTelegramWebView);
        };
        
        checkBrowser();
    }, []);

    const openStridetonApp = () => {
        const packageName = 'com.strideton';
        const scheme = 'strideton://app';
        const playStoreUrl = `https://play.google.com/store/apps/details?id=${packageName}`;
        const appStoreUrl = 'https://apps.apple.com/app/your-app-id';
        
        const userAgent = navigator.userAgent.toLowerCase();
        const isAndroid = userAgent.includes('android');
        const isIOS = /iphone|ipad|ipod/.test(userAgent);

        try {
            if (isAndroid) {
                // Прямое открытие приложения через схему
                window.location.replace(scheme);
                
                // Если приложение не открылось через 1.5 секунды, открываем Play Store
                setTimeout(() => {
                    window.location.replace(playStoreUrl);
                }, 1500);
            } else if (isIOS) {
                // Для iOS используем Universal Links если есть
                const now = Date.now();
                
                // Пробуем открыть приложение
                window.location.replace(scheme);
                
                // Проверяем, открылось ли приложение
                setTimeout(() => {
                    if (Date.now() - now < 1600) {
                        window.location.replace(appStoreUrl);
                    }
                }, 1500);
            } else {
                window.location.replace(playStoreUrl);
            }
        } catch (e) {
            // Если что-то пошло не так, открываем магазин приложений
            window.location.replace(isAndroid ? playStoreUrl : appStoreUrl);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-100">
            <button
                onClick={openStridetonApp}
                className="px-6 py-3 text-lg font-medium rounded-lg shadow-md hover:opacity-90 active:opacity-80 transition-all"
                style={{
                    backgroundColor: WebApp.themeParams.button_color || '#3390EC',
                    color: WebApp.themeParams.button_text_color || '#ffffff'
                }}
            >
                Открыть приложение
            </button>
            
            <p className="mt-4 text-sm text-gray-600 text-center">
                Если приложение не открывается автоматически,
                <br />вы будете перенаправлены в магазин приложений
            </p>
        </div>
    );
};

export default TelegramWebApp;